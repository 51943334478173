import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Grid, Loader, Message } from "semantic-ui-react";

import { SideBar, TitleSection, ConfirmationModal, AdminSideBar, SocialNetworksSection } from "../../../components";
import {
  Media,
  Post,
  UploadProgressMap,
  UserGroups,
  VideoPreferences,
  VideoProjectDetail,
  VideoProjectMessage,
  VideoProjectStatus,
  VideoProjectUpdateRequest,
} from "../../../interfaces";
import { AppContext } from "../../../providers";

import { URLS, finishStep, capitalizeFormatter, validatePendingAck, urlWithFormat } from "../../../utils";
import { VideoProjectServices } from "../../../services";

import { VideoProjectInQueueForm } from "./components/VideoProjectForm";
import { modalReducer } from "./reducer";
import { VideoProjectPageType, descriptors } from "./descriptors";
import { validateMediasRequiremenst } from "./validations";

import { CustomBox, CustomColor, WhiteContainer } from "../../../styling/baseStyle";
import "./styles.scss";
import { VideoProjectBubbleDetails } from "./components/BubbleDetails";
import { useSocialNetworks } from "../../../reducers";
import { useMemo } from "react";
import { ProjectFileUploader } from "../../../utils/projectFileUploader";
import { ConfigContext } from "../../../contexts/appContexts";
import { UploadProgressModal } from "../../../components/UploadProgressModal";
import { buildPreferenceOptions, StylePreferencesOptions } from "../../../utils/projectHelpers";
import moment from "moment";

type Props = RouteComponentProps;

const VideoProjectFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { userContext } = React.useContext(AppContext);
  const { firebase } = React.useContext(ConfigContext);
  const { formatMessage } = useIntl();
  const [state, dispatch] = React.useReducer(modalReducer, {
    open: false,
    status: "ACTIVE",
    archive: false,
    action: "close",
  });

  const [loading, setLoading] = React.useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = React.useState(false);
  const [uploadProgressMap, setUploadProgressMap] = React.useState<UploadProgressMap>();
  const [error, setError] = React.useState<boolean>(false);
  const [formErrorMessages, setFormErrorMessages] = React.useState<string[] | undefined>(undefined);

  const [videoProject, setVideoProject] = React.useState<VideoProjectDetail | undefined>(undefined);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [post, setPost] = React.useState<Post | undefined>(undefined);
  const [videoProjectMessages, setVideoProjectMessages] = React.useState<VideoProjectMessage[] | undefined>(undefined);
  const [needsAck, setNeedsAck] = React.useState<boolean>(false);

  const [videoPreferences, setVideoPreferences] = React.useState<VideoPreferences | undefined>(undefined);
  const [preferencesInfo, setPreferencesInfo] = React.useState<StylePreferencesOptions[]>([]);

  const readyToEdit = videoProject?.status === VideoProjectStatus.SUBMITTED_FOR_EDITING;
  // Messages
  const titleMessage: string = formatMessage({
    ...descriptors[VideoProjectPageType.title],
  });
  const saveButtonTitle: string = formatMessage({
    ...descriptors[readyToEdit ? VideoProjectPageType.saveBtn : VideoProjectPageType.resubmitBtn],
  });
  const generalError = formatMessage({
    ...descriptors[VideoProjectPageType.unknownError],
  });

  const postSnIds = useMemo(() => {
    return post?.socialNetworks?.filter(sn => sn.status === "POSTED").map(sn => sn.socialNetworkId);
  }, [post?.socialNetworks]);

  const postSnUrls = useMemo(() => {
    let snUrls = {};

    post?.socialNetworks?.map(sn => {
      if (sn.externalPostUrl && sn.socialNetworkId !== "FB") {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: urlWithFormat(sn.externalPostUrl!.replace("[", "").replace("]", "")),
        };
      } else {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: sn.externalPostUrl!,
        };
      }
    });
    return snUrls;
  }, [post?.socialNetworks]);

  const { sns } = useSocialNetworks();

  React.useEffect(() => {
    if (params["id"]) {
      setLoading(true);
      VideoProjectServices.get(params["id"])
        .then(vp => {
          setVideoProject(vp);
          setPost(vp.post);
          setEditable(vp.status !== VideoProjectStatus.POSTED);
          setVideoProjectMessages(vp.messages);
          setNeedsAck(validatePendingAck(vp.ackList, "EDITOR"));
          setVideoPreferences(vp.preferences);
        })
        .catch(() => {
          alert("Error getting video project info");
          history.goBack();
        })
        .finally(() => {
          setLoading(false);
        });
      VideoProjectServices.acknowledgeMessage(params["id"]).catch(e => {
        console.error("acknowledgeMessage error", e);
      });
    }
  }, [params["id"]]);

  const getErrors = (): string[] | undefined => {
    const errors: string[] = [];
    const validMedias = validateMediasRequiremenst(videoProject?.editedMedia);

    if (!validMedias) {
      errors.push(
        formatMessage({
          ...descriptors[VideoProjectPageType.wrongSpecificationsMessage],
        })
      );
    }

    if (
      videoProject?.editedMedia !== undefined &&
      videoProject?.editedMedia.media !== undefined &&
      videoProject?.editedMedia.media.length > 0
    ) {
      videoProject?.editedMedia?.media.forEach(media => {
        if (!media.validAspectRatio && media.validAspectRatio !== undefined) {
          errors.push(
            formatMessage(
              {
                ...descriptors[VideoProjectPageType.wrongAspectRatioMessage],
              },
              { type: media.type.split("/")[0], typeAfterDot: capitalizeFormatter(media.type.split("/")[0]) }
            )
          );
        }

        if (media.errors?.wrongBitrate) {
          errors.push(
            formatMessage({
              ...descriptors[VideoProjectPageType.wrongBitrateMessage],
            })
          );
        }
        if (media.errors?.wrongLength) {
          errors.push(
            formatMessage({
              ...descriptors[VideoProjectPageType.wrongDurationoMessage],
            })
          );
        }
        if (media.errors?.wrongSize) {
          errors.push(
            formatMessage(
              {
                ...descriptors[VideoProjectPageType.wrongSizeMessage],
              },
              { type: media.type.split("/")[0], typeAfterDot: capitalizeFormatter(media.type.split("/")[0]) }
            )
          );
        }
      });
    }

    return errors.length > 0 ? errors : undefined;
  };

  React.useEffect(() => {
    setFormErrorMessages(() => getErrors());
  }, [videoProject]);

  const updateVideoProject = async (): Promise<any> => {
    if (
      videoProject &&
      videoProject.editedMedia &&
      videoProject.editedMedia?.media &&
      videoProject.editedMedia?.media.length > 0
    ) {
      const { editedMedia, playerId, coachId, preferredPostDate } = videoProject;
      const updated: any = videoProject;

      const socialNetworkMessages = videoProject!!.socialNetworks
        ? videoProject!!.socialNetworks!!.map((key: string) => {
            const sn = {
              code: key,
            };
            return sn;
          })
        : [];

      const uploader = new ProjectFileUploader(coachId!!, firebase);
      const uploadMap: UploadProgressMap = {};
      editedMedia.media.forEach((mediaObj: Media) => {
        uploadMap[mediaObj.id] = { progress: 0, task: undefined };
      });
      setUploadProgressMap(uploadMap);
      setUploadingFiles(true);
      editedMedia.media = await uploader.prepareMedia(editedMedia.media, (fileId, progress) => {
        setUploadProgressMap(map => {
          return { ...map, [fileId]: { progress, task: undefined } };
        });
      });
      setUploadingFiles(false);
      setLoading(true);

      const request: VideoProjectUpdateRequest = {
        playerId,
        coachId,
        socialNetworkMessages,
        hashtags: updated.hashtags ? updated.hashtags : undefined,
        messageToPost: videoProject!.message,
        status: videoProject.status,
        isAutoPost: videoProject!.isAutoPost,
        preferredPostDate,
        editedMediaGroup: editedMedia,
        allowShortLink: videoProject!.allowShortLink,
        allowComplianceDisclaimer: videoProject!.allowComplianceDisclaimer,
        allowPlayerHashtags: videoProject["allowHashtags"],
      };

      VideoProjectServices.update(request, videoProject!!.videoProjectId!!)
        .then(() => {
          setLoading(false);
          // history.goBack();
          history.replace(URLS.editor.editQueueList);
        })
        .catch(e => {
          setLoading(false);
          setFormErrorMessages([e.substring(0, 100)]);
        });
    } else {
      setFormErrorMessages(() => [
        formatMessage({
          ...descriptors[VideoProjectPageType.attributeRequired],
        }),
      ]);
    }
  };

  const closeAndGoToVideoQueue = () => {
    if (state.action === "cancel") {
      history.push(URLS.editor.editQueueList);
    } else {
      dispatch({ type: "close" });
    }
  };

  const markAsArchive = () => {
    dispatch({ type: "close" });
    if (state.action === "archive") {
      VideoProjectServices.archive(videoProject!.videoProjectId!!)
        .then(() => {
          history.push(URLS.editor.editQueueList);
        })
        .catch((e: any) => {
          alert(e);
        });
    }
  };

  const cancelAction = () => {
    dispatch({ type: "open_cancel", status: "ACTIVE" });
  };

  const confirmationModal = (): JSX.Element => {
    return (
      <ConfirmationModal
        title={formatMessage({
          ...descriptors[
            state.archive ? VideoProjectPageType.archiveAlertTitle : VideoProjectPageType.cancelAlertTitle
          ],
        })}
        message={""}
        openConfirmationModal={state.open}
        onClose={() => dispatch({ type: "close" })}
        rejectHandler={() => dispatch({ type: "close" })}
        okHandler={state.archive ? markAsArchive : closeAndGoToVideoQueue}
      />
    );
  };

  const archiveVideoProject = (): void => {
    dispatch({ type: "open_archive", status: "ACTIVE" });
  };

  const refreshMessages = (): void => {
    VideoProjectServices.get(`${videoProject?.videoProjectId!!}`).then((r: VideoProjectDetail) => {
      setVideoProjectMessages(r.messages);
    });
  };
  const customLinkSection = (): JSX.Element => (
    <Grid.Column width={9} floated="right" className="noMarginRight">
      <Grid>
        <Grid.Column width={5} floated="right" className="skip-link noMarginRight">
          <span className={"rightPadding"} data-elm-id={"videoProjectPageCancelBtn"} onClick={cancelAction}>
            {formatMessage({ ...descriptors[VideoProjectPageType.cancelBtn] })}
          </span>
        </Grid.Column>
        <Grid.Column width={10} floated="right" className="skip-link noMarginRight noPadding">
          <Button
            className={"secondary rounded"}
            data-elm-id={"videoProjectPageArchiveBtn"}
            onClick={archiveVideoProject}
          >
            {formatMessage({ ...descriptors[VideoProjectPageType.archiveBtn] })}
          </Button>
        </Grid.Column>
      </Grid>
    </Grid.Column>
  );

  React.useEffect(() => {
    setPreferencesInfo(buildPreferenceOptions(videoPreferences));
  }, [videoPreferences]);

  const content = (): JSX.Element => {
    return (
      <Grid columns={1} className={"contentSection"}>
        {confirmationModal()}
        <TitleSection
          title={titleMessage}
          ready={finishStep(userContext?.signUpStep)}
          customLinkSection={customLinkSection()}
          customNextLabel={saveButtonTitle}
          handleNextAction={() => updateVideoProject()}
          disableButtons={uploadingFiles}
          titleSize={8}
          buttonsSize={8}
          nextButtonSize={7}
          showLinkOption={editable}
          showNextOption={editable}
        />
        {error && <Message error content={generalError} />}

        <Grid className={"videoProjectFormSection leftPadding"}>
          <Grid.Row>
            <Grid.Column width={9} className={"formContainer"}>
              {videoProject && (
                <VideoProjectInQueueForm
                  videoProject={videoProject}
                  updatingHandler={setVideoProject}
                  pageErrors={formErrorMessages}
                />
              )}
            </Grid.Column>
            <Grid.Column width={6}>
              <Grid.Row>
                <Grid className={"videoProjectContainer"}>
                  <label className={"sectionHeader"}>
                    <FormattedMessage {...descriptors[VideoProjectPageType.postStatusLabel]} />
                  </label>
                  <Grid.Row>
                    <p className="noPadding">
                      {`${videoProject?.statusLabel} ${moment(videoProject?.statusDate).format("MM/DD/YYYY hh:mm a")}`}
                    </p>
                  </Grid.Row>
                  {postSnIds && postSnIds.length > 0 && (
                    <SocialNetworksSection actives={postSnIds} links={postSnUrls} allSocialNetworks={sns} />
                  )}
                </Grid>
              </Grid.Row>

              <Grid.Row>
                <Grid className={"videoProjectContainer"}>
                  <label className={"sectionHeader"}>
                    <FormattedMessage {...descriptors[VideoProjectPageType.preferencesLabel]} />
                  </label>
                  <Grid.Row className="stylePreferencesRow">
                    {preferencesInfo.map(preference => {
                      return (
                        <p className="noPadding">
                          {`\u2022 ${preference.key}`}
                          {preference.colors?.map(color => {
                            return (
                              <CustomBox>
                                <CustomColor fill={color} />
                                {color.toUpperCase()}
                              </CustomBox>
                            );
                          })}
                        </p>
                      );
                    })}
                  </Grid.Row>
                </Grid>
              </Grid.Row>

              {videoProjectMessages && (
                <VideoProjectBubbleDetails
                  onSuccess={refreshMessages}
                  videoProjectId={videoProject?.videoProjectId!!}
                  submitBtnLabel={"Add Message"}
                  messages={videoProjectMessages}
                  needsAck={needsAck}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <UploadProgressModal open={uploadingFiles} progressMap={uploadProgressMap} />
      </Grid>
    );
  };

  const isAdmin = userContext?.account.groups.some(g => g === UserGroups.ADMIN);
  return (
    <WhiteContainer>
      {isAdmin ? <AdminSideBar history={history} /> : <SideBar history={history} />}
      {loading ? <Loader active size="large" /> : content()}
    </WhiteContainer>
  );
};

export const EditorVideoProjectPage = withRouter(VideoProjectFC);
