import * as React from "react";
import { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid, Loader } from "semantic-ui-react";

import { ConfirmationModal, SideBar, TitleSection } from "../../../components";
import {
  dateKey,
  Media,
  timeKey,
  UploadProgressMap,
  VideoProjectRequest,
  VideoProjectSource,
  WebComponents,
} from "../../../interfaces";
import { AppContext } from "../../../providers";
import { coachWithWritePermission, getSocialNetworkMessages, URLS } from "../../../utils";
import { VideoProjectServices } from "../../../services";

import { VideoProjectForm } from "./components/VideoProjectForm";
import { modalReducer } from "./reducer";

import { descriptors, VideoProjectPageType } from "./descriptors";
import { WhiteContainer } from "../../../styling/baseStyle";
import "./styles.scss";
import { ProjectFileUploader } from "../../../utils/projectFileUploader";
import { ConfigContext } from "../../../contexts/appContexts";
import { UploadProgressModal } from "../../../components/UploadProgressModal";

type Props = RouteComponentProps;

const VideoProjectFC: React.FC<Props> = ({ history, location }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const { firebase } = React.useContext(ConfigContext);
  const writePermission = coachWithWritePermission(WebComponents.VIDEO_CATALYST, userContext);
  const coachId = useMemo(() => {
    return userContext?.coach?.id;
  }, [userContext?.coach?.id]);
  const [isValid, setIsValid] = React.useState(true);

  const [state, dispatch] = React.useReducer(modalReducer, {
    open: false,
    status: "ACTIVE",
    duplicated: false,
    action: "close",
  });

  // Properties
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formErrorMessages, setFormErrorMessages] = React.useState<string[] | undefined>(undefined);
  const [uploadingFiles, setUploadingFiles] = React.useState(false);
  const [uploadProgressMap, setUploadProgressMap] = React.useState<UploadProgressMap>();
  const [videoProject, setVideoProject] = React.useState<any>({});

  // Location params
  const searchParams = new URLSearchParams(location.search);
  const preloadedPlayerId = searchParams.get("playerId");

  const createVideoProject = async (): Promise<any> => {
    if (videoProject) {
      const {
        playerId,
        allowShortLink,
        allowComplianceDisclaimer,
        allowHashtags,
        isAutoPost,
        status,
        originalMediaGroup,
        withMessage,
        preferredPostDate,
        hashtags,
        initialMessages,
        preferences,
      } = videoProject;

      const snFiltered = Object.keys(videoProject.socialNetworkMessages).filter(
        key => videoProject.socialNetworkMessages[key]
      );
      const socialNetworkMessages = getSocialNetworkMessages(snFiltered);

      const uploader = new ProjectFileUploader(coachId!!, firebase);
      const uploadMap: UploadProgressMap = {};
      originalMediaGroup.media.forEach((mediaObj: Media) => {
        uploadMap[mediaObj.id] = { progress: 0, task: undefined };
      });
      setUploadProgressMap(uploadMap);
      setUploadingFiles(true);
      originalMediaGroup.media = await uploader.prepareMedia(originalMediaGroup.media, (fileId, progress) => {
        setUploadProgressMap(map => {
          return { ...map, [fileId]: { progress, task: undefined } };
        });
      });
      setUploadingFiles(false);
      setLoading(true);

      setLoading(true);

      const request: VideoProjectRequest = {
        playerId,
        coachId,
        socialNetworkMessages,
        messageToPost: withMessage,
        originalMediaGroup,
        hashtags,
        status,
        isAutoPost,
        preferredPostDate,
        allowShortLink,
        allowComplianceDisclaimer,
        initialMessages,
        allowPlayerHashtags: allowHashtags,
        preferences,
        source: VideoProjectSource.COACH_WEB,
      };

      VideoProjectServices.create(request)
        .then(newProject => {
          setLoading(false);
          history.replace(URLS.videoProject.replace(":id", `${newProject.videoProjectId}`));
        })
        .catch(error => {
          setLoading(false);
          setFormErrorMessages([error.substring(0, 100)]);
        });
    }
  };

  const closeAndGoToVideoCatalyst = () => {
    if (state.action === "cancel") {
      history.push(URLS.coach.videoCatalyst);
    } else {
      dispatch({ type: "close" });
    }
  };

  const cancelAction = () => {
    dispatch({ type: "open_cancel", status: "ACTIVE" });
  };

  const confirmationModal = (): JSX.Element => {
    const modalTitle = formatMessage({
      ...descriptors[VideoProjectPageType.cancelAlertTitle],
    });
    return (
      <ConfirmationModal
        title={!state.duplicated ? modalTitle : undefined}
        message={""}
        openConfirmationModal={state.open}
        onClose={() => dispatch({ type: "close" })}
        okHandler={() => {
          dispatch({ type: "close" });
          history.push(URLS.coach.videoCatalyst);
        }}
        rejectHandler={() => dispatch({ type: "close" })}
      />
    );
  };

  const customLinkSection = (disableButtons: boolean): JSX.Element => (
    <Grid.Column width={7} floated="right" className="skip-link noMarginRight">
      <span className={"rightPadding"} data-elm-id={"videoProjectPageCancelBtn"} onClick={cancelAction}>
        {formatMessage({ ...descriptors[VideoProjectPageType.cancelBtn] })}
      </span>
    </Grid.Column>
  );

  const content = (): JSX.Element => {
    return (
      <Grid columns={1} className={"newProjectContentSection contentSection"}>
        {confirmationModal()}
        <TitleSection
          title={formatMessage({ ...descriptors[VideoProjectPageType.title] })}
          ready={true}
          customLinkSection={customLinkSection(uploadingFiles)}
          customNextLabel={formatMessage({ ...descriptors[VideoProjectPageType.saveBtn] })}
          handleNextAction={() => createVideoProject()}
          disableButtons={uploadingFiles || !isValid || !writePermission}
          titleSize={8}
          buttonsSize={8}
          nextButtonSize={7}
          showLinkOption={writePermission}
          showNextOption={writePermission}
        />
        <Grid.Row columns={1} className={"formSection leftPadding"}>
          <Grid className={"infoSection"}>
            <Grid.Column width={16} className={"formContainer"}>
              <VideoProjectForm
                disabled={!writePermission}
                modeEditable={false}
                videoProject={videoProject}
                updatingHandler={setVideoProject}
                handleValidation={setIsValid}
                pageErrors={formErrorMessages}
                coachId={coachId!!}
                playerId={preloadedPlayerId}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
        <UploadProgressModal open={uploadingFiles} progressMap={uploadProgressMap} />
      </Grid>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      {loading ? <Loader active size="large" /> : content()}
    </WhiteContainer>
  );
};

export const VideoProjectPage = withRouter(VideoProjectFC);
