import { FirebaseConfig, Media } from "../interfaces";
import { FirebaseStorage, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { getFirebaseStorage, saveElementToFirebase } from "./firebase";
import { mediaVideoProjectFirebaseUrl } from "./routes";
import { createVideoThumbnailBlob, dataURLtoBlob } from "./vimeo";

class ProjectFileUploader {
  private readonly firebaseUrl: string;
  private readonly firebaseConfig: FirebaseConfig;
  private readonly firebaseStorage: FirebaseStorage;

  constructor(coachId: string, firebaseConfig: FirebaseConfig) {
    this.firebaseUrl = mediaVideoProjectFirebaseUrl(coachId);
    this.firebaseConfig = firebaseConfig;
    this.firebaseStorage = getFirebaseStorage(firebaseConfig);
  }

  async prepareMedia(mediaList: Media[], onUploadProgress: (fileId: number, progress: number) => void) {
    return Promise.all(
      mediaList.map(async (mediaInput): Promise<Media> => {
        let media: Media = {
          ...mediaInput,
          validAspectRatio: true,
          validDimension: true,
        };
        if (media.imageFile && !media.uri.startsWith("http")) {
          media = await this.uploadMediaToFirebase(media, onUploadProgress);
        }

        if (!media.thumbnailUri || media.thumbnailUri.length === 0) {
          if (media.category === "VIDEO") {
            media = await this.uploadThumbnailToFirebase(media);
          } else {
            media = {
              ...media,
              thumbnailUri: media.uri,
            };
          }
        }

        return media;
      })
    );
  }

  private generateDestUri(media: Media): string {
    const [type, format] = media.imageFile!!.type.split("/");

    if (!type) {
      throw Error("Invalid file type");
    }
    return `${this.firebaseConfig.baseImagesFolder}/${this.firebaseUrl}/${media.fileName}.${format}`;
  }

  private async uploadMediaToFirebase(
    media: Media,
    onUploadProgress: (fileId: number, progress: number) => void
  ): Promise<Media> {
    const destUri = this.generateDestUri(media);
    const storageRef = ref(this.firebaseStorage, destUri);
    const uploadTask = uploadBytesResumable(storageRef, media.imageFile!!);

    // set Firebase callback functions
    uploadTask.on("state_changed", snapshot => {
      const uploadProgress = snapshot.bytesTransferred / snapshot.totalBytes;
      onUploadProgress(media.id, uploadProgress);
    });

    await uploadTask;
    const uri = await getDownloadURL(storageRef);
    return {
      ...media,
      uri,
    };
  }

  private async uploadThumbnailToFirebase(media: Media): Promise<Media> {
    try {
      const thumbnail = await createVideoThumbnailBlob(media.uri);
      const blob = dataURLtoBlob(thumbnail);
      const thumbnailUri = await saveElementToFirebase(
        this.firebaseConfig,
        "thumbnail/" + media.fileName,
        blob,
        "thumbnail_" + media.fileName
      );
      return {
        ...media,
        thumbnailUri: thumbnailUri,
      };
    } catch (e) {
      console.error("Error creating thumbnail", e);
      return media;
    }
  }
}

export { ProjectFileUploader };
